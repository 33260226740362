.animation-button{
  background: none;
  border: 2px solid #000;
  /* padding: 10px 60px; */
  font-size: 1rem;
  color: red;
  position: relative;
}

.animation-button:hover{
  /* border: 2px solid #dfdeff;; */
  background: rgb(29, 28, 28);
  border-radius: 10px;
}