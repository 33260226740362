.home-div{
  color: red;
}

.contract-address{
  color: white;
  text-align: center;
}

.instructions{
  text-align: center;
  color: white;
}

.home-top{
  display: flex;
  justify-content:space-between;
  align-items: self-start;
  margin: 0 30px;
}

.homeLinks{
  display: flex;
  flex-direction: column;
  align-items: self-end;
}

.home-logo{
  max-width:100px;
  max-height:30px;
  width: auto;
  height: auto;
  margin: 0 10px;
}
.home-logo-npm{
  max-height:35px;
}

.logo-div{
  display: flex;
  align-items:center;
  font-size: 21px;
}

.animation-div{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: 20px 20px;
}

.homeTitle{
  white-space: pre;
  text-align: center;
}

.logo-div > a{
  text-decoration: none;
  display:flex;
  align-items:center;
  color:red;
}